import request from '@/utils/request'


// 查询预算列表
export function listBudget(query) {
  return request({
    url: '/budget/budget/list',
    method: 'get',
    params: query
  })
}

// 查询预算分页
export function pageBudget(query) {
  return request({
    url: '/budget/budget/page',
    method: 'get',
    params: query
  })
}

// 查询预算详细
export function getBudget(data) {
  return request({
    url: '/budget/budget/detail',
    method: 'get',
    params: data
  })
}

// 新增预算
export function addBudget(data) {
  return request({
    url: '/budget/budget/add',
    method: 'post',
    data: data
  })
}

// 修改预算
export function updateBudget(data) {
  return request({
    url: '/budget/budget/edit',
    method: 'post',
    data: data
  })
}

// 删除预算
export function delBudget(data) {
  return request({
    url: '/budget/budget/delete',
    method: 'post',
    data: data
  })
}

//用户预算文件上传
export function postFileList(data) {
  return request({
    url: '/budget/budget/uploadReport',
    method: 'post',
    data: data
  })
}
