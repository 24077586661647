<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>预算管理</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!-- <a-form-model-item label="用户id" prop="userId">
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="预算名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入预算名称" />
      </a-form-model-item> -->
      <a-form-model-item label="预算文件" prop="fileIds">
        <!-- <a-input v-model="form.fileIds" placeholder="请输入内容" type="textarea" allow-clear /> -->
        <file-upload :defaultList="filelist" @input="handleChange" :count="10" />
      </a-form-model-item>
      <!-- <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBudget, addBudget, updateBudget, postFileList } from '@/api/budget/budget'
import { guid } from '@/utils/ruoyi'
import { getMulFile } from '@/api/file/file'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      filelist: [],
      // 表单参数
      form: {
        id: null,

        userId: null,

        name: null,

        fileIds: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [{ required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '预算名称不能为空', trigger: 'blur' }],
        fileIds: [{ required: true, message: '预算文件,外键{zb_file.id},多个使用逗号隔开不能为空', trigger: 'blur' }],
      },
      id: '',
      fileAddParamList: [],
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        name: null,
        fileIds: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.filelist = []
      this.id = row.id
      //   getBudget({ id: id }).then((response) => {
      //     this.form = response.data
      //     this.open = true
      //     this.formTitle = '修改'
      //   })

      this.filelist.push({
        uid: guid(8, 10),
        name: row.fileIds,
        status: 'done',
        halfUrl: row.fileIds,
        url: row.fileIds,
        path: row.fileIds,
      })

      getMulFile(row.fileIds.split(',')).then((res) => {
        // console.log('获取的报告', res)
        this.filelist = []
        res.data.forEach((item) => {
          this.filelist.push({
            uid: item.id,
            name: item.name,
            status: 'done',
            url: item.url,
          })
        })
      })

      this.open = true
    },
    /** 提交按钮 */
    submitForm: function () {
      //   this.$refs.form.validate((valid) => {
      //     if (valid) {
      //       this.submitLoading = true
      //       if (this.form.id !== undefined && this.form.id !== null) {
      //         updateBudget(this.form)
      //           .then((response) => {
      //             this.$message.success('修改成功', 3)
      //             this.open = false
      //             this.$emit('ok')
      //           })
      //           .finally(() => {
      //             this.submitLoading = false
      //           })
      //       } else {
      //         addBudget(this.form)
      //           .then((response) => {
      //             this.$message.success('新增成功', 3)
      //             this.open = false
      //             this.$emit('ok')
      //           })
      //           .finally(() => {
      //             this.submitLoading = false
      //           })
      //       }
      //     } else {
      //       return false
      //     }
      //   })

      //   if (this.filelist.length != 0) {
      postFileList({
        fileAddParamList: this.fileAddParamList,
        id: this.id,
      }).then((res) => {
        this.$message.success('提交成功')
        this.onClose()
      })
      //   }
    },
    handleChange(fileList) {
      //  console.log(e)
      //   const picurl = []
      //   fileList.map((item) => {
      //     picurl.push(item.url)
      //   })
      //   this.form.fileIds = picurl.join(',')
      this.fileAddParamList = []
      fileList.map((item) => {
        this.fileAddParamList.push({
          extend: item.url.split('.')[4],
          id: item.uid.replace(/\D/g, '').length < 18 ? '' : item.uid,
          name: item.name,
          url: item.url,
        })
      })
    },
  },
}
</script>
